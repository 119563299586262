#cart {
    width: 470px;
    background: #fff;
}
.cart-content {
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    font-size: 14px;
    background-color: #f7f7f7;
}

#cart .react-numeric-input > input {
    width: 60px;
}

#cart label {
    font-weight: 600 !important;
    font-size: 14px;
}
#cart .loading-image {
    height: 58vh;
    overflow: hidden;
}
#cart .horizontal-line {
    border-bottom: 1px solid #cacaca;
    margin: 0;
}

#cart .dropdown-menu {
    border-color: gray;
}
.cart-container input[type="checkbox"] {
    /* transform: scale(1.4); */
    border: none;
    margin-top: 2px;
}
.cart-selected {
    background-color: rgb(215, 231, 190);
}
.cart-remove-button{
    margin-top: 10px;
}

@media (min-width: 768px) {
    #cart {
        width: 900px;
    }
    .cart-content {
        max-height: 490px;
        background-color: inherit;
    }
    .cart-submit-button, .cart-remove-button  {
        float: right;
        margin-right: 6px;
    }
}
.btn-cart-close {
    position: absolute;
    right: 5px;
    top: 20px;
    z-index: 9;
}
.cart-header {
    margin-right: 3px;
    margin-left: 0;
}
@media (max-width: 900px) {
    #cart {
        width: 97vw;
    }
}
.cart-image {
    width: 65px;
}
@media (max-width: 500px) {
    .cart-image {
        width: 50px;
        padding-left: 5px;
    }
}
.cart-submit-button-mobile, .cart-remove-button-mobile  {
    position: absolute;
    right: 1rem;
}
.cart-remove-button-mobile {
    margin-top: 40px;
}
@media (max-width: 450px) {
    .cart-content {
        margin: 0 -1rem;
    }
    .cart-submit-button-mobile, .cart-remove-button-mobile  {
        position: absolute;
        right: 12px;
    }
}
