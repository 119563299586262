[hidden] {
    display: none;
}

#popup {
    width: 93vw;
    overflow-y: auto;
    overflow-x: hidden;
    cursor: default;
    margin-bottom: 6px;
    user-select: none;
    max-height: 55vh;
}

.horizontal-line-container {
    margin: 0;
    padding: 0 2%;
}

.horizontal-line {
    border-bottom: 1px solid gray;
    margin: 10px 0;
}

/* small (sm) size display */
@media (min-width: 768px) {
    #popup {
        width: 600px;
        max-height: 55vh;
    }
}

.popup-parent.dropdown-menu {
    box-shadow: 0 0 6px lightgray;
    background-color: whitesmoke;
}

.popup-container {
    height: fit-content !important;
    padding: 1rem;
    text-align: center;
}

.categoryRow {
    margin: 0;
}

.categoryRow h3 {
    margin-bottom: 0;
}

.popup-icon-container {
    display: block;
    box-sizing: border-box;

    width: 100%;
    margin: 0 0 24px 0;
    /* padding: 0 6px 6px 6px; */
    cursor: pointer;
}

.popup-icon-container:hover {
    font-weight: 500;
}

.popup-icon-container p {
    margin-bottom: 0;
    font-size: 14px;
}

.popup-icon {
    height: 150px;
    width: auto;
    background-clip: content-box;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 2px;
    box-shadow: 0 0 2px black;
    position: relative;
    border: 12px solid white;
}

.popup-icon:hover,
.popup-icon.active {
    box-shadow: 0.5px 0.5px 6px 2px #86a33c;
}

.categoryHeading p {
    margin: 6px 0 0 0;
    text-align: left;
    font-size: 20px;
}

.contentCol {
    border-radius: 2px;
    /* height: 500px; */
    overflow-x: hidden;
    cursor: default;
    margin: 0;
    padding: 0 8px;
}

.navigationCol {
    margin: 0;
    padding: 0;
    background-color: white;
    border-radius: 2px;
}

.rowContainer {
    margin: 0 0 0 6px;
    padding: 0;
    width: 100%;
}

.navigationHeading {
    text-align: left;
    font-size: 18px;
    padding: 6px 6px;
}

.navigationElementContainer {
    width: 100%;
    margin: auto;
}

.navigationElement {
    padding: 1px 6px;
    border-top: 1px solid lightgray;
    text-align: left;
    cursor: pointer;
    transition: 0.1s;
}

.navigationElement:hover {
    background-color: rgb(245, 245, 245);
    transition: 0.2s;
}

.navigationElement:active {
    background-color: rgb(243, 243, 243);
    box-shadow: 0 0 16px -4px rgb(0, 0, 0, 0.2) inset;
    transition: 0.1s;
}

.clearFilterContainer {
    width: 100%;
    background-color: whitesmoke;
    float: right;
}

.clearFilter {
    width: 72px;
    padding: 0 4px 2px 0;
    float: right;
    font-size: 12px;
    text-align: right;
}

.clearFilter:hover {
    text-decoration: underline;
    cursor: pointer;
}

/* .smallElements .popup-icon-container {
    height: 120px;
} */
/* .smallElements p {
    margin-bottom: 8px;
}

.mediumElements .popup-icon-container {
    height: 160px;
}
.mediumElements p {
    margin-bottom: 8px;
}

.bigElements .popup-icon-container {
    height: 240px;
}
.bigElements p {
    margin-bottom: 8px;
} */
#popup p {
    font-size: 14px;
    margin-bottom: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
#popup img.selected-item {
    width: 44px;
}
#popup .popup-color .popup-icon {
    height: 100px;
}
#popup .popup-color img.selected-item {
    width: 44px;
}
