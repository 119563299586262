button,
.btn {
	outline: 0 !important;
}
button:focus,
.btn:focus {
	-webkit-box-shadow: none;
	box-shadow: none;
}
.btn-primary {
	color: #fff;
	background-color: #86a33c;
	border-color: #86a33c;
}
.btn-configurator {
	color: #212121;
	background-color: #fbfafa;
	border: 0;
	border-radius: 5px;
	box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2) !important;
	/* font-size: 14px; */
	font-weight: 500;
	padding-left: 20px;
	padding-right: 20px;
	/* transition: all 0.3s ease 0s; */
}
.btn-configurator:hover {
	background-color: #dcdcdc;
}
button.btn-configurator.saved {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23212529' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
}
.btn-selector {
	color: #212121;
	background-color: #dcdcdc;
	border: 0;
	border-radius: 5px;
	font-size: 14px;
	font-weight: 500;
	padding-left: 20px;
	padding-right: 20px;
	/* transition: all 0.3s ease 0s; */
}
.btn-selector-white {
	color: #212121;
	background-color: #fbfafa;
	border: 0;
	border-radius: 5px;
	font-size: 14px;
	font-weight: 500;
	padding-left: 20px;
	padding-right: 20px;
	/* transition: all 0.3s ease 0s; */
}
.btn-selector:hover,
.btn-selector-white:hover {
	color: #000;
	/* background-color: #dcdcdc; */
}

.btn-secondary,
.btn-primary {
	color: #fff;
	background-color: #86a33c;
	outline: 0 !important;
	border: 0;
}
.btn-secondary:hover,
.btn-primary:hover,
.btn-secondary:not(:disabled):active,
.btn-primary:not(:disabled):active,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled).active,
.show > .btn-secondary.dropdown-toggle {
	color: #fff;
	background-color: #86a33c;
}
.btn-secondary:disabled {
	color: #fff;
	background-color: #86a33c;

	outline: 0 !important;
	cursor: default !important;
}
.btn-secondary.bordered {
	border-color: #fff !important;
	border-width: 1px;
	border-style: solid;
}
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
	background: #86a33c;
}
.btn-secondary.action-button,
.btn-configurator.action-button {
	padding: 6px 40px;
}
.btn-secondary.action-button.narrow,
.btn-configurator.action-button.narrow {
	padding: 6px 1rem;
}

button.btn-secondary.saved {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23FFFFFF' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
}
button.btn-secondary.saved,
button.btn-configurator.saved {
	background-repeat: no-repeat;
	background-size: 18px;
	background-position-x: calc(100% - 14px);
	background-position-y: center;
}

.btn-gray {
	background-color: #dcdcdc;
	color: #343a40;
}
.btn-gray:hover,
.btn-gray:not(:disabled):active,
.btn-gray:not(:disabled).active,
.show > .btn-gray.dropdown-toggle {
	background-color: #dcdcdc;
	color: #343a40;
}
.btn-gray:disabled {
	color: #fff;
	background-color: #86a33c;

	outline: 0 !important;
	cursor: default !important;
}
.dropdown-toggle:hover,
.dropdown-toggle:active,
.dropdown-toggle:focus {
	-webkit-box-shadow: none;
	box-shadow: none;
	border-color: #fff;
}

.btn-group > .btn-configurator.btn:focus,
.btn-group > .btn-configurator.btn:active {
	z-index: 1;
	background: #dcdcdc;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
	box-shadow: none;
}

.btn-cart-close span {
	font-size: 1.2rem;
}
.btn .fa {
	margin-left: -20px;
	padding-right: 13px;
}
.btn.cell-button .fa,
.btn.link-button .fa {
	margin: 0;
	padding: 0;
}
.btn-none .fa {
	margin: 0;
	padding: 0;
}
.admin .btn-selector {
	background-color: #e9ecef;
	border: 1px solid #d2d2d2;
}
.cell-button {
	padding: 0;
	max-height: 16px;
	vertical-align: top;
}
.cell-button:hover > i,
.cell-button:hover > span {
	color: #000 !important;
}
.cell-button i {
	font-size: 16px;
	color: #565656;
}
.small-button {
	padding: 0.375rem 0.75rem !important;
}
@media (max-width: 767px) {
	.small-button {
		padding: 3px 13px !important;
	}
}

.action-button .saving {
	width: 27px;
	margin-right: -30px;
	padding-left: 8px;
}
.cell-link {
	padding: 0;
	max-height: 16px;
	vertical-align: top;
}
.cell-link:hover {
	color: #000 !important;
	text-decoration: underline;
}

.link-button {
	padding: 0;
	line-height: 100%;
}
.link-button:hover {
	text-decoration: underline;
}
.btn-change {
	position: absolute;
	height: calc(1.5em + 0.75rem + 2px);
	padding: 6px 6px !important;
	right: 5px;
	top: 27px;
}
