.subnavbar {
    background-color: #fff;
    padding: 0;
    border-bottom: 1px solid #cecece;
}

.subnav-link.active {
    background-color: #dcdcdc;
}

.navbar-expand-lg .subnavbar-nav .subnav-link {
    padding: 1rem 1.5rem 1rem 1.5rem;
}

.navbar-expand-lg .subnavbar-nav .subnav-link:hover {
    background-color: #dcdcdc;
}

.number-circle {
    border-radius: 50%;
    width: 26px;
    height: 26px;
    padding: 2px 2px 2px 3px;
    background: #43515a;
    color: #fff;
    border: 2px solid #43515a;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    font-family: Arial;
    display: inline-block;
    margin-right: 8px;
    display: none;
}

.model-price-wrapper {
    flex: 1;
    text-align: right;
    padding-right: 10px;
    height: 50px;
}

.model-price-wrapper .model{
    padding: 5px 15px 5px 15px;
    display: inline-block;
    text-align: center;
    font-size: 14px;
}
.navbar-title{
    font-size: 15px;
    display: none;
}
.model-price-wrapper .model label {
    text-align: center;
    display: block;
}
@media (max-width: 559px) {
    .model-price-wrapper{
        flex: 100%;
        height: auto;
    }
    .navbar-title{
        flex: 1;
    }
    .model-price-wrapper .model{
        padding: 5px 10px 5px 10px;
    }
}
@media (max-width: 767px) {
    a.text-dark.subnav-link.nav-link {
        font-size: 14px;
    }
}
@media (max-width: 992px) {   
    .navbar-title{
        display: inline-block;
    }
}
@media (max-width: 1200px) {
    .model-price-wrapper .model,
    .model-price-wrapper .price {
        font-size: 13px;
    }
    .navbar-expand-lg .subnavbar-nav .subnav-link {
        padding: 1rem;
    }
}
.document-configuration-icon {
    width: 38px;
    vertical-align: sub;
    margin-right: 15px;
    cursor: pointer;
}
.subnav-item a {
    line-height: 30px;
}
