.admin .customer-view .nav-tabs .nav-link {
	color: #343a40 !important;
	font-size: 16px;
	padding: 1rem 1.5rem 1rem 1.5rem;
	background-color: #fff;
	cursor: pointer;
	border: none;
}
.admin .admin-menu {
	position: relative;
}
.admin .admin-menu-item {
	padding-left: 0;
	width: 100%;
}
.admin .admin-menu-item.active:before {
	content: '\f053';
	position: absolute;
	top: 5px;
	right: -5px;
	font-family: FontAwesome;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	font-size: 20px;
	color: #c5c5c5;
}
.admin .admin-form-border {
	border: 2px solid #f7f7f7;
	padding: 1rem !important;
	background-color: #f7f7f7;
}
.admin .admin-form-borderless {
	padding: 1rem !important;
	background-color: #f7f7f7;
}
.admin .form-row {
	margin-right: 0;
	margin-left: 0;
}
.admin .configurator-background {
	background-color: #dcdcdc;
	min-height: calc(100vh - 145px);
	margin-top: 0 !important;
}
.admin .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
	color: #495057;
	background-color: #dcdcdc;
	border-radius: 0;
}
.admin .form-control {
	color: #212121;
}
.admin select[disabled] > option {
	color: #212121;
}
.admin .form-control:disabled,
.form-control[readonly] {
	background-color: #e9ecef;
}
.admin .nav-tabs {
	border: none;
}
.admin .form-control:focus {
	box-shadow: none;
}
.admin a.documentation-link {
	color: #86a33c;
	text-decoration: none;
}
.admin a.documentation-link:hover {
	color: #212529;
	text-decoration: none;
}
.admin .loader.documentation-loader {
	border-top-color: rgb(255, 152, 0);
	width: 30px;
	position: relative;
	width: 30px;
	height: 30px;
	border-width: 4px;
	display: inline-block;
	margin-left: 15px;
	vertical-align: middle;
	left: auto;
	top: auto;
}
.admin .filter-separator span {
	background-color: #f7f7f7;
}
.admin .pagination {
	margin-top: 1rem;
}
.admin .react-bs-table-pagination {
	margin-top: 1rem;
	position: relative;
	right: 0;
}
.react-bs-table table td,
.react-bs-table table td button.btn,
.react-bs-table table th {
	font-size: 14px !important;
}
.nav-dropdown {
	display: none;
}
.nav-dropdown .nav-items {
	padding-left: 10px;
	width: 100%;
}
@media (max-width: 1700px) {
	.react-bs-table table td,
	.react-bs-table table td button.btn,
	.react-bs-table table th {
		font-size: 13px !important;
	}
	.react-bs-table .react-bs-container-footer > table > thead > tr > th .filter,
	.react-bs-table .react-bs-container-header > table > thead > tr > th .filter {
		font-size: 13px;
	}
	.admin .customer-view .nav-tabs .nav-link {
		font-size: 14px;
	}
	.admin .navbar-brand,
	.form-control {
		font-size: 14px;
	}
}
@media (max-width: 1400px) {
	.admin .admin-menu-item.active:before {
		top: 3px;
		right: -20px;
	}
}

@media (max-width: 992px) {
	#configurator.admin .configurator-background {
		background: none;
		background-color: transparent;
	}
	#configurator.admin .customer-images.configurator-background,
	.customer-view.configurator-background {
		background-color: #dcdcdc;
	}
	.admin .admin-form-border {
		background-color: #ffffff;
	}
	.admin .calculation .admin-form-border {
		background-color: transparent;
	}
	.admin .admin-menu-item.active:before {
		display: none;
	}
	#configurator a.navbar-brand.active {
		font-weight: 700;
		color: #4e4e4e;
	}
	.react-bs-table-pagination > div > div {
		display: none;
	}
	.admin .pagination {
		margin-top: 1rem;
		float: left;
	}
	.nav-dropdown {
		width: 100%;
		display: inline;
	}
	.nav-form {
		display: none;
	}
}
.admin label.form-check-label {
	display: inline-block;
}
.admin input[type='checkbox'] {
	cursor: pointer;
}
.admin .react-bs-table textarea {
	background-color: transparent !important;
	border-color: transparent !important;
	font-size: 13px !important;
	padding-top: 0 !important;
}
.admin td.no-elipsis {
	overflow: visible;
	white-space: normal;
	text-overflow: unset;
}
.admin .inquiry .table-hover tbody tr:hover {
	background-color: rgb(218, 218, 218);
}
.table-summary {
	margin: 5px 0 !important;
	text-align: right;
}
.tax-dropdown {
	float: right;
}

.print-dropdown {
	display: inline-block;
	padding: 5px;
}
.configuration-price {
	text-transform: uppercase;
	font-weight: 700;
}
.react-date-picker__wrapper {
	border: none !important;
}
