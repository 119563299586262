.Register {
    text-align: left;
    padding: 1em;
    margin: 1em;
    border: 2px solid #d3d3d3;
    border-radius: 0.5em;
    vertical-align: middle;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
}
.toggle-password {
    float: right;
    margin-right: 16px;
    margin-top: -24px;
    position: relative;
    z-index: 2;
}
input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
    display: none;
}
.form {
    padding: 1em;
}

label {
    display: flex;
    font-weight: 600;
}

button {
    justify-content: flex-end;
}

.Register-title {
    font-size: 1.5em;
}

.Register-intro {
    font-size: large;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
