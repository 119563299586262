@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
	/* overflow-y: scroll; */
	font-size: 14px;
}
body {
	font-family: 'Roboto', sans-serif;
	color: #212121;
	background-color: #f7f7f7;
	padding: 0 !important;
}
.dropdown-selector {
	font-size: inherit !important;
}
.text-light {
	color: #fff !important;
}

a.text-light:hover,
a.text-light:focus {
	color: #cbd3da !important;
}

.text-dark {
	color: #343a40 !important;
}

a.text-dark:not(.nav-link):hover,
a.text-dark:not(.nav-link):focus {
	color: #777 !important;
}

.dropdown-item.active,
.dropdown-item:active {
	color: inherit;
	background-color: #dcdcdc;
}
.no-padding {
	padding-right: 0 !important;
	padding-left: 0 !important;
}
.no-margin {
	margin-right: 0 !important;
	margin-left: 0 !important;
}
.inline-image {
	display: inline-block;
	vertical-align: middle;
}

.text-small {
	font-size: 14px;
}
.text-smaller {
	font-size: 12px;
}
input[type='radio'],
.form-check-label {
	cursor: pointer;
}
/* input[type=text].form-control:focus, input[type=search].form-control:focus {
    border-color: #86A33C;
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.2);
} */

.navbar-brand {
	font-size: 1rem;
	letter-spacing: normal;
}

label {
	font-weight: normal !important;
}
label.smaller {
	margin-bottom: 0;
	font-size: 14px;
}
input.smaller,
textarea.smaller {
	font-size: 14px;
}
.switch-wrapper {
	position: relative;
}

.switch-wrapper > div {
	position: absolute;
	width: 100%;
}
/* .container {
    max-width:100% !important;
    padding-left:2rem;
    padding-right:2rem;

} */
.navbar-toggler {
	border: 0;
}
header .navbar {
	padding: 0.5rem 0;
	box-shadow: 0 0.6666666667em 3.6666666667em 0 rgba(0, 0, 0, 0.1);
	z-index: 1;
}
header .nav-link {
	padding: 0.5rem 0;
}
.disable-cursor {
	cursor: default !important;
}
a,
.link-button {
	color: #345ec1;
}
a.green {
	color: #86a33c;
}
a:hover {
	color: #212121;
}
/* .form-control:focus {
    border-color: #86A33C;
    box-shadow: 0 0 0 0.1rem #86A33C;
} */
.form-control:focus {
	border-color: #495057;
	box-shadow: none !important;
}
.react-bootstrap-table-page-btns-ul {
	margin-top: -10px !important;
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected {
	background-color: #86a33c !important;
}
#loading {
	display: none;
}
.modal-open {
	overflow: visible;
}
.was-validated .form-control:valid,
.form-control.is-valid {
	border-color: #86a33c !important;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23FFFFFF' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
}
div.show-valid {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23FFFFFF' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
	width: 18px;
	height: 36px;
	display: inline-block;
	background-repeat: no-repeat;
	background-position: center;
	vertical-align: middle;
	margin-left: 7px;
}
/* .form-control.is-valid:focus {
    border: 0;
    box-shadow: 0 0 0 1px #495057;
} */
/* .was-validated .form-control:valid, .form-control.is-valid {
    border-color:transparent;
} */
.page-link {
	position: relative;
	display: block;
	padding: 0.5rem 0.75rem;
	margin-left: -1px;
	line-height: 1.25;
	color: #86a33c;
	background-color: #fff;
	border: 1px solid #dee2e6;
	font-size: 14px !important;
}
.table-striped tbody tr:nth-of-type(odd) {
	background-color: rgba(242, 245, 245, 0.8);
}
.table-striped tbody tr:nth-of-type(even) {
	background-color: #fff;
}
.react-bs-table-bordered {
	border-radius: 0 !important;
	border: 1px solid #cecece !important;
}
.react-bs-table table td {
	outline: none !important;
}
.categories-loader {
	position: fixed;
	left: 0;
	/* right: 0; */
	top: 0;
	/* bottom: 0; */
	width: 100%;
	/* margin: 0 auto; */
	height: 100%;
	background: rgba(0, 0, 0, 0.2);
	/* background-color: white; */
}
.loader {
	border: 16px solid #f3f3f3; /* Light grey */
	border-top: 16px solid #3498db; /* Blue */
	border-radius: 50%;
	width: 120px;
	height: 120px;
	-webkit-animation: spin 2s linear infinite;
	        animation: spin 2s linear infinite;
	position: absolute;
	left: calc(50% - 60px);
	top: 27vh;
}
.loader.part-loader {
	top: calc(38vh - 60px);
}
@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}
@keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}
.loader.fixed {
	position: relative;
}
.loader.top {
	top: 0;
}
.categories-loader .loader {
	top: 40%;
}
.login-loader.loader {
	top: 25vh;
}
@media (min-width: 768px) {
	.categories-loader {
		display: none;
	}
}
:focus {
	outline: none;
}
@media (max-width: 767px) {
	body,
	.dropdown-menu {
		font-size: 14px;
	}
	header .dropdown {
		padding-right: 5px;
		padding-left: 5px;
	}
}
.form-control.is-valid,
.was-validated .form-control:valid,
.form-control.is-invalid,
.was-validated .form-control:invalid {
	background-position-x: calc(100% - 10px);
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-transition: background-color 5000s ease-in-out 0s;
	transition: background-color 5000s ease-in-out 0s;
}
.table {
	color: #4e4e4e;
}
.no-pointer-events {
	pointer-events: none;
}
body,
.dropdown-menu,
.btn,
.form-control,
.dropdown-selector {
	font-size: 14px !important;
}
.navbar-brand {
	font-size: 14px;
}
.close span {
	font-size: 18px;
}
.cursor-pointer {
	cursor: pointer;
}
a.cookie-info {
	color: #f7f7f7;
	text-decoration: underline;
}
.cookie-info:hover {
	color: #dcdcdc;
	text-decoration: underline;
}
.react-numeric-input b {
	width: 2.3ex !important;
}

.required:after {
	content: ' *';
	color: red;
}

.AppFooter {
	position: fixed;
	right: 0;
	z-index: 5;
	bottom: 0;
	background-color: rgba(255, 255, 255, 0.7);
	padding: 1px 10px;
	border-top-left-radius: 4px;
}

.AppFooterCopyright,
.AppFooterGdpr,
.AppFooterTerms,
.AppFooterVersion {
	float: right;
	font-size: 0.7em;
	font-weight: 400;
	color: #000;
	padding-right: 20px;
	text-shadow: 1px 1px 1px rgba(162, 162, 162, 0.8);
}

.AppFooterLink {
	color: #000;
}

button,
.btn {
	outline: 0 !important;
}
button:focus,
.btn:focus {
	box-shadow: none;
}
.btn-primary {
	color: #fff;
	background-color: #86a33c;
	border-color: #86a33c;
}
.btn-configurator {
	color: #212121;
	background-color: #fbfafa;
	border: 0;
	border-radius: 5px;
	box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2) !important;
	/* font-size: 14px; */
	font-weight: 500;
	padding-left: 20px;
	padding-right: 20px;
	/* transition: all 0.3s ease 0s; */
}
.btn-configurator:hover {
	background-color: #dcdcdc;
}
button.btn-configurator.saved {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23212529' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
}
.btn-selector {
	color: #212121;
	background-color: #dcdcdc;
	border: 0;
	border-radius: 5px;
	font-size: 14px;
	font-weight: 500;
	padding-left: 20px;
	padding-right: 20px;
	/* transition: all 0.3s ease 0s; */
}
.btn-selector-white {
	color: #212121;
	background-color: #fbfafa;
	border: 0;
	border-radius: 5px;
	font-size: 14px;
	font-weight: 500;
	padding-left: 20px;
	padding-right: 20px;
	/* transition: all 0.3s ease 0s; */
}
.btn-selector:hover,
.btn-selector-white:hover {
	color: #000;
	/* background-color: #dcdcdc; */
}

.btn-secondary,
.btn-primary {
	color: #fff;
	background-color: #86a33c;
	outline: 0 !important;
	border: 0;
}
.btn-secondary:hover,
.btn-primary:hover,
.btn-secondary:not(:disabled):active,
.btn-primary:not(:disabled):active,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled).active,
.show > .btn-secondary.dropdown-toggle {
	color: #fff;
	background-color: #86a33c;
}
.btn-secondary:disabled {
	color: #fff;
	background-color: #86a33c;

	outline: 0 !important;
	cursor: default !important;
}
.btn-secondary.bordered {
	border-color: #fff !important;
	border-width: 1px;
	border-style: solid;
}
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
	background: #86a33c;
}
.btn-secondary.action-button,
.btn-configurator.action-button {
	padding: 6px 40px;
}
.btn-secondary.action-button.narrow,
.btn-configurator.action-button.narrow {
	padding: 6px 1rem;
}

button.btn-secondary.saved {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23FFFFFF' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
}
button.btn-secondary.saved,
button.btn-configurator.saved {
	background-repeat: no-repeat;
	background-size: 18px;
	background-position-x: calc(100% - 14px);
	background-position-y: center;
}

.btn-gray {
	background-color: #dcdcdc;
	color: #343a40;
}
.btn-gray:hover,
.btn-gray:not(:disabled):active,
.btn-gray:not(:disabled).active,
.show > .btn-gray.dropdown-toggle {
	background-color: #dcdcdc;
	color: #343a40;
}
.btn-gray:disabled {
	color: #fff;
	background-color: #86a33c;

	outline: 0 !important;
	cursor: default !important;
}
.dropdown-toggle:hover,
.dropdown-toggle:active,
.dropdown-toggle:focus {
	box-shadow: none;
	border-color: #fff;
}

.btn-group > .btn-configurator.btn:focus,
.btn-group > .btn-configurator.btn:active {
	z-index: 1;
	background: #dcdcdc;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
	box-shadow: none;
}

.btn-cart-close span {
	font-size: 1.2rem;
}
.btn .fa {
	margin-left: -20px;
	padding-right: 13px;
}
.btn.cell-button .fa,
.btn.link-button .fa {
	margin: 0;
	padding: 0;
}
.btn-none .fa {
	margin: 0;
	padding: 0;
}
.admin .btn-selector {
	background-color: #e9ecef;
	border: 1px solid #d2d2d2;
}
.cell-button {
	padding: 0;
	max-height: 16px;
	vertical-align: top;
}
.cell-button:hover > i,
.cell-button:hover > span {
	color: #000 !important;
}
.cell-button i {
	font-size: 16px;
	color: #565656;
}
.small-button {
	padding: 0.375rem 0.75rem !important;
}
@media (max-width: 767px) {
	.small-button {
		padding: 3px 13px !important;
	}
}

.action-button .saving {
	width: 27px;
	margin-right: -30px;
	padding-left: 8px;
}
.cell-link {
	padding: 0;
	max-height: 16px;
	vertical-align: top;
}
.cell-link:hover {
	color: #000 !important;
	text-decoration: underline;
}

.link-button {
	padding: 0;
	line-height: 100%;
}
.link-button:hover {
	text-decoration: underline;
}
.btn-change {
	position: absolute;
	height: calc(1.5em + 0.75rem + 2px);
	padding: 6px 6px !important;
	right: 5px;
	top: 27px;
}

header nav {
    background-color: #86a33c;
    border: 0;
}

header a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
    color: #fff;
    font-weight: 100;
    font-size: 22px;
    vertical-align: middle;
    margin-right: 0;
    padding: 0;
    display: flex;
    align-items: baseline;
    max-height: 40px;
}

header {
    font-size: 14px;
}

@media (min-width: 768px) {
    header {
        font-size: 16px;
    }
}

header .box-shadow {
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
}

header .dropdown {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}

header .fa {
    padding-right: 2px;
}

header .logo {
    max-width: 120px;
    max-height: 40px;
    vertical-align: middle;
}

header .logotip {
    max-width: 100px;
    max-height: 40px;
    right: 0;  
    margin: auto;
}
header .logo-frame {
    height: 40px;
    max-width: 100px;
    text-align: right;
    vertical-align: middle;
    display: table-cell;
}
.outer-wrapper{
        margin-left: auto;
        display: inline-block; 
        margin-top: 3px;
    }

.logo-title {
    margin-left: 5px;
}

@media (max-width: 575px) {
    header .logo {
        margin-left: 10px;
        max-width: 120px;
        max-height: 32px;
    }
    .navbar-nav button.bordered.dropdown-toggle.btn.btn-secondary {
        padding: 0px;
        border: 0;
        margin: 2px 0;
    }

    .header-menu .navbar-nav{
        justify-content: flex-end;
    }
    header a.navbar-brand {
        font-size: 18px;
    }
    .outer-wrapper{
        display: none;
    }
}
header .header-menu button span {
    padding-right: 15px;
    padding-left: 5px;
    vertical-align: middle;
}
@media (max-width: 575px) {
    header .header-menu .dropdown-toggle::after {
        display: none;
    }
}
header .header-menu .dropdown-toggle::after {
    vertical-align: middle;
}
.header-menu .navbar-nav {
    flex-direction: row;
}

.navbar-toggler-icon {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAHCAYAAAA8sqwkAAAAiklEQ…kJPOZV42SDE3ycxYsjY/In2DRsgb2dkp5rYqOU8qq1zsDuA06eMUefCnqYAAAAAElFTkSuQmCC);
}
@media (max-width: 1100px) {
    .logo-title {
        display: none;
    }
}

.react-numeric-input > input {
    width: 70px;
}

header .navbar-nav .dropdown-menu {
    margin-top: 6px;
}

#cart {
    width: 470px;
    background: #fff;
}
.cart-content {
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    font-size: 14px;
    background-color: #f7f7f7;
}

#cart .react-numeric-input > input {
    width: 60px;
}

#cart label {
    font-weight: 600 !important;
    font-size: 14px;
}
#cart .loading-image {
    height: 58vh;
    overflow: hidden;
}
#cart .horizontal-line {
    border-bottom: 1px solid #cacaca;
    margin: 0;
}

#cart .dropdown-menu {
    border-color: gray;
}
.cart-container input[type="checkbox"] {
    /* transform: scale(1.4); */
    border: none;
    margin-top: 2px;
}
.cart-selected {
    background-color: rgb(215, 231, 190);
}
.cart-remove-button{
    margin-top: 10px;
}

@media (min-width: 768px) {
    #cart {
        width: 900px;
    }
    .cart-content {
        max-height: 490px;
        background-color: inherit;
    }
    .cart-submit-button, .cart-remove-button  {
        float: right;
        margin-right: 6px;
    }
}
.btn-cart-close {
    position: absolute;
    right: 5px;
    top: 20px;
    z-index: 9;
}
.cart-header {
    margin-right: 3px;
    margin-left: 0;
}
@media (max-width: 900px) {
    #cart {
        width: 97vw;
    }
}
.cart-image {
    width: 65px;
}
@media (max-width: 500px) {
    .cart-image {
        width: 50px;
        padding-left: 5px;
    }
}
.cart-submit-button-mobile, .cart-remove-button-mobile  {
    position: absolute;
    right: 1rem;
}
.cart-remove-button-mobile {
    margin-top: 40px;
}
@media (max-width: 450px) {
    .cart-content {
        margin: 0 -1rem;
    }
    .cart-submit-button-mobile, .cart-remove-button-mobile  {
        position: absolute;
        right: 12px;
    }
}

#configurator .sideBarMenuContainer {
	position: fixed;
	height: 100%;
}

#configurator .userMenu {
	width: 100%;
	text-align: center;
	margin-right: 0px;
	margin-left: 0px;
	margin-top: 0px;
}

#configurator nav.sidebar :global(.navbar-brand) :global(.glyphicon) {
	margin-right: 0px;
}

/*Remove rounded coners*/
#configurator nav.sidebar :global(navbar) {
	border-radius: 0px;
}

#configurator nav.sidebar {
	transition: margin 200ms ease-out;
}

/* .....NavBar: Icon only with coloring/layout.....*/

/*small/medium side display*/
@media (min-width: 768px) {
	/*Center Brand*/
	#configurator nav.sidebar :global(navbar).sidebar > .container :global(.navbar-brand),
	:global(.navbar) > :global(.container-fluid) :global(.navbar-brand) {
		margin-left: 0px;
	}
	/*Center Brand*/
	#configurator nav.sidebar :global(.navbar-brand),
	nav.sidebar :global(.navbar-header) {
		text-align: center;
		width: 100%;
		margin-left: 0px;
	}

	/*Center Icons*/
	#configurator nav.sidebar a {
		padding-right: 13px;
	}

	/*adds border top to first nav box */
	#configurator nav.sidebar :global(.navbar-nav) > li:first-child {
		border-top: 1px #e5e5e5 solid;
	}

	/*adds border to bottom nav boxes*/
	#configurator nav.sidebar :global(.navbar-nav) > li {
		border-bottom: 1px #e5e5e5 solid;
	}

	/* Colors/style dropdown box*/
	#configurator nav.sidebar :global(.navbar-nav) :global(.open) :global(.dropdown-menu) {
		position: static;
		float: none;
		width: auto;
		margin-top: 0;
		background-color: transparent;
		border: 0;
		box-shadow: none;
	}

	/*allows nav box to use 100% width*/
	#configurator nav.sidebar :global(.navbar-collapse),
	nav.sidebar :global(.container-fluid) {
		padding: 0 0px 0 0px;
	}

	/*colors dropdown box text */
	#configurator :global(.navbar-inverse) :global(.navbar-nav) :global(.open) :global(.dropdown-menu) > li > a {
		color: #777;
	}

	/*gives sidebar width/height*/
	#configurator nav.sidebar {
		width: 200px;
		height: 100%;
		margin-left: -160px;
		float: left;
		z-index: 8000;
		margin-bottom: 0px;
	}

	/*give sidebar 100% width;*/
	#configurator nav.sidebar li {
		width: 100%;
	}

	/* Move nav to full on mouse over*/
	#configurator nav.sidebar:hover {
		margin-left: 0px;
	}
	/*for hiden things when navbar hidden*/
	#configurator :global(.forAnimate) {
		opacity: 0;
	}
}

/* .....NavBar: Fully showing nav bar..... */
@media (min-width: 1330px) {
	/*Show all nav*/
	#configurator nav.sidebar {
		margin-left: 0px;
		float: left;
	}
	/*Show hidden items on nav*/
	#configurator nav.sidebar :global(.forAnimate) {
		opacity: 1;
	}
}

#configurator nav.sidebar :global(.navbar-nav) :global(.open) :global(.dropdown-menu) > li > a:hover,
nav.sidebar :global(.navbar-nav) :global(.open) :global(.dropdown-menu) > li > a:focus {
	color: #ccc;
	background-color: transparent;
}

#configurator nav:hover :global(.forAnimate) {
	opacity: 1;
}

#configurator section {
	padding-left: 15px;
}
#configurator .navbar-wrapper {
	width: 100%;
	border-top: 1px solid #cacaca;
}
#configurator.admin .navbar-wrapper:first-child {
	border-top: none;
}
#configurator .admin-menu.navbar-wrapper {
	border-top: none;
}
#configurator .navbar-toggler-icon,
.inquiry .navbar-toggler-icon {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAHCAYAAAA8sqwkAAAAjUlEQVQYlY2QsQ2DQAxFH2ECZmGBaxiBjBBGoP1rsAIpKaG4AZIVUoYxQD8yBQ2KJcu+5/+tuyuAWtKbP0JSXQLfnPOaUro0SXoA0w1YgEHScCH2zLn4YNYDG/ACKrPgVTDPerMiBha0scVxjzpG7SQ93fhKh8GgAT7AHOm+OcQnQ5j8cJss+C04/SCwA6nvNAAHZuIGAAAAAElFTkSuQmCC);
}
#configurator .navbar-toggler-icon,
.inquiry .navbar-toggler-icon {
	display: inline-block;
	width: 0.5em;
	height: 0.5em;
	vertical-align: baseline;
	content: '';
	background-repeat: no-repeat;
	background-position: center center;
	background-size: auto;
}
#configurator .navbar-toggler {
	padding-left: 0;
	padding-right: 0;
}
#configurator legend {
	font-size: 14px;
	font-weight: 700;
}
/* #configurator .navbar {
    border-top: 1px solid #6F6F6F;
    border-bottom: 1px solid #6F6F6F;
} */
#configurator .door-item {
	padding: 1rem 2rem 1rem 2rem;
	cursor: pointer;
}

@media (min-width: 1000px) {
	#configurator .door-item {
		padding: 1rem;
	}
	#configurator .doors-wrapper {
		padding: 0 1rem;
	}
}
@media (min-width: 1500px) {
	#configurator .door-item {
		padding: 1.5rem 2rem 0.5rem 2rem;
	}
}
@media (min-width: 1800px) {
	#configurator .door-item {
		padding: 1.5rem 2.5rem 0.5rem 2.5rem;
	}
}
#configurator .door-item {
	transition: 0.3s ease-in-out;
	cursor: pointer;
	text-align: center;
}
#configurator .door-item:hover {
	-webkit-transform: scale(1.07);
	transform: scale(1.07);
	transition: 0.3s ease-in-out;
}
#configurator .door-item div {
	text-align: center;
	text-overflow: ellipsis;
	overflow: hidden;
}
#configurator .door-item > img {
	vertical-align: middle;
	max-width: 100%;
}
#configurator img.selected-item {
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	position: absolute;
	z-index: 1;
	width: 56px;
}
#configurator.step2 .configurator-background,
#configurator.step3 .configurator-background {
	min-height: calc(100vh);
}
@media (max-width: 1400px) {
	#configurator img.selected-item {
		width: 42px;
	}
}
@media (max-width: 1400px) {
	#configurator input[type='search'] {
		font-size: 14px;
	}
}
@media (max-width: 992px) {
	.search-icon {
		display: none;
	}
}
@media (min-width: 768px) {
	#configurator .configurator-background {
		background-color: #dcdcdc;
	}
	.configurator-buttons .button.next-page {
		width: 27%;
		margin-right: 3%;
		margin-bottom: 0px;
	}
}

#configurator input[type='search'] {
	background-color: #dcdcdc;
	border: none;
	height: 45px;
	border-radius: 0;
	/* -webkit-box-shadow: none;
    box-shadow: none; */
}

.react-bs-table table {
	font-size: 14px;
}

/* #configurator textarea.form-control {
    background-color: #eae9e9;
    border-color: #dcdcdc;
    color:#212121;
    border-radius: 0;
    -webkit-box-shadow: none;
        box-shadow: none;
} */

#configurator input.form-control::-webkit-input-placeholder, #configurator textarea.form-control::-webkit-input-placeholder {
	color: inherit;
	opacity: 1;
}

#configurator input.form-control:-ms-input-placeholder, #configurator textarea.form-control:-ms-input-placeholder {
	color: inherit;
	opacity: 1;
}

#configurator input.form-control::placeholder,
#configurator textarea.form-control::placeholder {
	color: inherit;
	opacity: 1;
}
#configurator input.form-control::-webkit-input-placeholder, #configurator textarea.form-control::-webkit-input-placeholder {
	color: inherit;
}
#configurator input.form-control:-ms-input-placeholder, #configurator textarea.form-control:-ms-input-placeholder {
	color: inherit;
}
#configurator input.form-control::placeholder,
#configurator textarea.form-control::placeholder {
	color: inherit;
}
#configurator input.form-control::-webkit-input-placeholder, #configurator textarea.form-control::-webkit-input-placeholder {
	color: inherit;
}
#configurator input.form-control:-ms-input-placeholder, #configurator textarea.form-control:-ms-input-placeholder {
	color: inherit;
}
#configurator input.form-control::placeholder,
#configurator textarea.form-control::placeholder {
	color: inherit;
}

#configurator .parameter-image {
	display: block;
	margin-left: 50%;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	padding-bottom: 3rem;
	/* height: 80vh;  */
	max-width: 100%;
	padding-top: 1rem;
}
#configurator .configurator-button {
	display: inline-block;
	padding: 5px 10px;
}
.search-icon {
	position: absolute;
	margin-left: -75px;
	margin-top: 2px;
	padding: 10px;
	cursor: pointer;
	opacity: 0.6;
}
.clear-icon {
	position: absolute;
	margin-left: -56px;
	margin-top: 14px;
	/* padding: 10px; */
	cursor: pointer;
	width: 16px;
}
.search-input::-webkit-search-cancel-button {
	-webkit-appearance: none;
}
/*filters*/
.filter-reset {
	text-align: right;
	cursor: pointer;
	margin-top: 23px;
}
.configuration-reset {
	text-align: right;
	cursor: pointer;
	float: right;
	padding-top: 7px;
	margin-right: -30px;
}
.filters-navbar {
	padding-right: 0;
}
/* .filters-navbar.filters-navbar .navbar-brand {margin-bottom:0.5rem; margin-top:1rem} */
.filters-navbar .form-check {
	margin-bottom: 5px;
}
.filter-image {
	display: inline-block;
	vertical-align: middle;
	top: 13px;
	right: 15px;
	position: absolute;
}
.disable-edit .filter-image {
	display: none;
}
.filter-image-s {
	right: 15px;
	top: -21px;
	position: absolute;
	display: inline-block;
	cursor: pointer;
}
.filter-separator {
	width: 100%;
	height: 12px;
	border-bottom: 1px solid #cacaca;
	text-align: center;
}
.filter-separator span {
	padding-right: 1rem;
	background-color: #f5f5f5;
	font-size: 14px;
	float: left;
}

.configuration-filter button {
	width: 100%;
	padding-right: 40px;
	min-height: 33px;
	min-width: 140px;
	position: relative;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.configurator-filters.navbar {
	padding: 0 0 1rem 0;
}
.btn-group > .btn-configurator.btn:focus,
.btn-group > .btn-configurator.btn:active {
	z-index: 1;
	background: #dcdcdc;
}

.filters-navbar.filters-navbar .navbar-brand {
	width: calc(100% - 28px);
	cursor: pointer;
}
.step1 .filters-navbar.filters-navbar .navbar-brand {
	width: calc(100% - 10px);
	cursor: pointer;
}
.toggler-step3 {
	width: 113%;
}
.overview {
	width: 100%;
	font-size: 14px;
	overflow-x: hidden;
}
#configurator .overview a.navbar-brand {
	font-weight: 500;
	font-size: 14px;
}
.overview thead tr {
	background: rgb(253, 253, 253);
}

.overview th {
	background-color: #f5f5f5;
	font-weight: 500;
}
.overview thead:first-child th {
	padding-top: 1rem;
}
.overview th.th-right {
	background-color: #f5f5f5;
	padding: 1rem;
}
.admin .overview thead:first-child th {
	padding: 2rem 2rem 1rem 2rem;
}
.admin .overview th.th-right {
	background-color: #f5f5f5;
	padding: 2rem 2rem 1rem 2rem;
}
.overview th.th-left {
	padding: 1rem;
}
.admin .overview th.th-left {
	padding: 2rem 2rem 1rem 2rem;
}
.overview td {
	padding: 0.5rem;
}

.overview .spacer {
	height: 0.5rem;
	background: rgb(253, 253, 253);
	border: none;
	padding: 0;
}

.overview .money-col {
	float: right;
}

.overview hr {
	padding: 0;
	margin: 8px 0;
}

.inquiry {
	margin: auto;
}

.inquiry .head-column {
	width: 100%;
}
.inquiry .toggler-title {
	font-size: 15px;
	font-weight: 500;
}
.inquiry .half-column {
	width: 100%;
	padding: 10px 20px;
	margin-bottom: -20px;
}

.inquiry .full-column {
	width: 100%;
	padding: 30px 20px 0 20px;
}

.inquiry .foot-column {
	width: 100%;
	padding: 20px 20px 0 60px;
}

.inquiry input {
	margin-bottom: 10px;
}

.inquiry input[type='search'] {
	background-color: #dcdcdc;
}

.inquiry .checkbox {
	padding: 0 20px 0 20px;
}

input[type='checkbox'] {
	-webkit-transform: scale(1.3);
	        transform: scale(1.3);
	border: none;
}
.inquiry input[type='checkbox'] {
	-webkit-transform: scale(1.7);
	        transform: scale(1.7);
	border: none;
	margin-left: -14px;
}

.contact input[type='checkbox'] {
	-webkit-transform: scale(1.7);
	        transform: scale(1.7);
	border: none;
	margin-left: 5px;
}

.contact .checkbox {
	padding: 0 20px 0 30px;
	margin-top: 5px;
}

.Register .checkbox {
	padding: 0 20px 0 20px;
}

.Register input[type='checkbox'] {
	margin-left: 0px;
}

.configurator-filters input[type='checkbox'] {
	-webkit-transform: none;
	        transform: none;
	border: none;
}
.configurator-filters .navbar-toggler-icon {
	margin-top: -5px;
}
.configurator-buttons {
	width: 100%;
	margin-top: 20px;
	background-color: #f7f7f7;
	position: sticky;
	bottom: -1px;
	left: 0;
	z-index: 1;
}
.configurator-buttons .button {
	height: 50px;
}
.configurator-buttons .button.send-inquiry {
	width: 100%;
}
.inquiry hr {
	background-color: darkgray;
}

.inquiry .foot-column .bolder {
	font-weight: 500 !important;
	margin-right: 20px;
}

.inquiry .consensus-button {
	float: right;
	margin-left: 10px;
	border: none !important;
}

.inquiry h6 {
	position: relative;
	overflow: hidden;
	text-align: left;
	font-weight: normal;
}

.inquiry h6:after {
	display: inline-block;
	content: '';
	position: absolute;
	width: 100%;
	top: 50%;
	margin-top: -2px;
	margin-left: 10px;
	background-color: darkgray;
	height: 1px;
}
.inquiry #newproject-checkbox {
	margin-left: 5px;
}
.copy-popup input[type='checkbox'] {
	margin-left: 3px;
}
.copy-popup .checkbox {
	padding: 0 20px 0 25px;
}
.inquiry-popup {
	max-width: 50vw;
}
@media (max-width: 700px) {
	.inquiry-popup {
		max-width: 100vw;
	}
}
.step-of {
	line-height: 30px;
	padding-left: 1rem;
}
.step-of label {
	display: block;
}
button.dropdown-toggle.btn.btn-categories {
	text-transform: uppercase;
	font-size: 1.1rem;
	padding: 1rem 0;
}
.dropdown-categories .dropdown-menu {
	padding: 0;
}
.dropdown-categories .dropdown-item {
	text-transform: uppercase;
	font-size: 1.1rem;
	padding: 0.5rem 1.5rem;
}
.dropdown-item:hover,
.dropdown-item:focus {
	color: #16181b;
	text-decoration: none;
	background-color: #dcdcdc !important;
}

.category-row {
	border-bottom: 1px solid gray;
}
.type-IntegerT .dropdown-menu.show {
	width: 100%;
	border: 0;
	padding: 5px;
}
.slider {
	width: 100%;
	margin-top: -36px;
	text-align: center;
}
.slider-spacer {
	margin-top: 16px;
}
.slider input {
	height: 23px;
	color: #212121;
	font-size: 14px;
	font-weight: 500;
	text-align: center;
	outline-color: #86a33c;
	max-width: 120px;
}
.slider-spacer-bellow {
	height: 56px;
	background-color: #f7f7f7;
}
.configuration-filter.type-1 .dropdown-menu {
	width: 100%;
	border: 0;
	height: 50px;
}
.customer-view .customer-table {
	margin-bottom: 20px;
}
.customer-view .table-hover tbody tr:hover,
.inquiry .table-hover tbody tr:hover {
	background-color: rgb(218, 218, 218);
}
.customer-view .customer-table th input {
	font-size: 14px;
	height: 100% !important;
	margin-top: 8px;
}
.customer-view .table thead tr th,
.table tbody tr td {
	border: none;
}
.loading-image {
	position: relative;
	height: 90vh;
	width: 100%;
}
.loading-image.part {
	height: 80vh;
}
.loading-image img {
	width: 150px;
	position: absolute;
	left: calc(50% - 75px);
	top: calc(50% - 130px);
	opacity: 0.28;
}
#configurator a.navbar-brand {
	color: #212121;
}
/*admin part*/
.admin-configurator-buttons {
	position: absolute;
	z-index: 9999;
	top: 21px;
}
.react-bs-table-sizePerPage-dropdown {
	display: none;
}
.react-bs-table-pagination {
	margin-top: -6px;
	position: absolute;
	right: 1rem;
}
.page-item.active .page-link {
	z-index: 1;
	color: #fff;
	background-color: #b7b7b7;
	border-color: #b7b7b7;
}

.cart-container {
	padding: 1rem !important;
}

#configurator .fa-expand {
	padding-top: 10px;
	margin-right: -15px;
	font-size: 20px;
}
#configurator.step1 .loader {
	left: 1rem;
	top: 2rem;
	border: 16px solid #fff;
}
@media print {
	#configurator .parameter-image {
		height: 50vh;
	}
	.AppFooter {
		display: none;
	}
}
.special-bold {
	font-weight: 500 !important;
}
@media (max-width: 767px) {
	.configurator-filters.navbar {
		padding: 0 !important;
	}
	.configurator-buttons {
		position: fixed;
		padding-bottom: 40px;

		.next-page,
		.tree {
			width: 40% !important;
		}
	}
	#configurator.step2 .configurator-background,
	#configurator.step3 .configurator-background {
		min-height: calc(100vh + 120px);
	}
}

.treemodal {
	max-width: 100%;
}

.parameter-image-loading {
	position: absolute;
	height: 100%;
	width: 100%;
	opacity: 0.7;
	background-color: #dcdcdc;
	z-index: 2000;
}

.subnavbar {
    background-color: #fff;
    padding: 0;
    border-bottom: 1px solid #cecece;
}

.subnav-link.active {
    background-color: #dcdcdc;
}

.navbar-expand-lg .subnavbar-nav .subnav-link {
    padding: 1rem 1.5rem 1rem 1.5rem;
}

.navbar-expand-lg .subnavbar-nav .subnav-link:hover {
    background-color: #dcdcdc;
}

.number-circle {
    border-radius: 50%;
    width: 26px;
    height: 26px;
    padding: 2px 2px 2px 3px;
    background: #43515a;
    color: #fff;
    border: 2px solid #43515a;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    font-family: Arial;
    display: inline-block;
    margin-right: 8px;
    display: none;
}

.model-price-wrapper {
    flex: 1 1;
    text-align: right;
    padding-right: 10px;
    height: 50px;
}

.model-price-wrapper .model{
    padding: 5px 15px 5px 15px;
    display: inline-block;
    text-align: center;
    font-size: 14px;
}
.navbar-title{
    font-size: 15px;
    display: none;
}
.model-price-wrapper .model label {
    text-align: center;
    display: block;
}
@media (max-width: 559px) {
    .model-price-wrapper{
        flex: 100% 1;
        height: auto;
    }
    .navbar-title{
        flex: 1 1;
    }
    .model-price-wrapper .model{
        padding: 5px 10px 5px 10px;
    }
}
@media (max-width: 767px) {
    a.text-dark.subnav-link.nav-link {
        font-size: 14px;
    }
}
@media (max-width: 992px) {   
    .navbar-title{
        display: inline-block;
    }
}
@media (max-width: 1200px) {
    .model-price-wrapper .model,
    .model-price-wrapper .price {
        font-size: 13px;
    }
    .navbar-expand-lg .subnavbar-nav .subnav-link {
        padding: 1rem;
    }
}
.document-configuration-icon {
    width: 38px;
    vertical-align: sub;
    margin-right: 15px;
    cursor: pointer;
}
.subnav-item a {
    line-height: 30px;
}

[hidden] {
    display: none;
}

#popup {
    width: 93vw;
    overflow-y: auto;
    overflow-x: hidden;
    cursor: default;
    margin-bottom: 6px;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    max-height: 55vh;
}

.horizontal-line-container {
    margin: 0;
    padding: 0 2%;
}

.horizontal-line {
    border-bottom: 1px solid gray;
    margin: 10px 0;
}

/* small (sm) size display */
@media (min-width: 768px) {
    #popup {
        width: 600px;
        max-height: 55vh;
    }
}

.popup-parent.dropdown-menu {
    box-shadow: 0 0 6px lightgray;
    background-color: whitesmoke;
}

.popup-container {
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
    padding: 1rem;
    text-align: center;
}

.categoryRow {
    margin: 0;
}

.categoryRow h3 {
    margin-bottom: 0;
}

.popup-icon-container {
    display: block;
    box-sizing: border-box;

    width: 100%;
    margin: 0 0 24px 0;
    /* padding: 0 6px 6px 6px; */
    cursor: pointer;
}

.popup-icon-container:hover {
    font-weight: 500;
}

.popup-icon-container p {
    margin-bottom: 0;
    font-size: 14px;
}

.popup-icon {
    height: 150px;
    width: auto;
    background-clip: content-box;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 2px;
    box-shadow: 0 0 2px black;
    position: relative;
    border: 12px solid white;
}

.popup-icon:hover,
.popup-icon.active {
    box-shadow: 0.5px 0.5px 6px 2px #86a33c;
}

.categoryHeading p {
    margin: 6px 0 0 0;
    text-align: left;
    font-size: 20px;
}

.contentCol {
    border-radius: 2px;
    /* height: 500px; */
    overflow-x: hidden;
    cursor: default;
    margin: 0;
    padding: 0 8px;
}

.navigationCol {
    margin: 0;
    padding: 0;
    background-color: white;
    border-radius: 2px;
}

.rowContainer {
    margin: 0 0 0 6px;
    padding: 0;
    width: 100%;
}

.navigationHeading {
    text-align: left;
    font-size: 18px;
    padding: 6px 6px;
}

.navigationElementContainer {
    width: 100%;
    margin: auto;
}

.navigationElement {
    padding: 1px 6px;
    border-top: 1px solid lightgray;
    text-align: left;
    cursor: pointer;
    transition: 0.1s;
}

.navigationElement:hover {
    background-color: rgb(245, 245, 245);
    transition: 0.2s;
}

.navigationElement:active {
    background-color: rgb(243, 243, 243);
    box-shadow: 0 0 16px -4px rgb(0, 0, 0, 0.2) inset;
    transition: 0.1s;
}

.clearFilterContainer {
    width: 100%;
    background-color: whitesmoke;
    float: right;
}

.clearFilter {
    width: 72px;
    padding: 0 4px 2px 0;
    float: right;
    font-size: 12px;
    text-align: right;
}

.clearFilter:hover {
    text-decoration: underline;
    cursor: pointer;
}

/* .smallElements .popup-icon-container {
    height: 120px;
} */
/* .smallElements p {
    margin-bottom: 8px;
}

.mediumElements .popup-icon-container {
    height: 160px;
}
.mediumElements p {
    margin-bottom: 8px;
}

.bigElements .popup-icon-container {
    height: 240px;
}
.bigElements p {
    margin-bottom: 8px;
} */
#popup p {
    font-size: 14px;
    margin-bottom: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
#popup img.selected-item {
    width: 44px;
}
#popup .popup-color .popup-icon {
    height: 100px;
}
#popup .popup-color img.selected-item {
    width: 44px;
}

.react-tags {
    position: relative;
    text-align: left;
    font-size: 1em;
    line-height: 1.2;
    cursor: text;
    margin-left: -2px;
    float: left;
}

.react-tags.is-focused {
    border-color: #b1b1b1;
}

.react-tags__selected {
    display: inline;
}

.react-tags__selected-tag {
    display: inline-block;
    box-sizing: border-box;
    margin: 0 6px 6px 0;
    border: 1px solid #212529;
    border-radius: 0;
    background: #fff;
    width: auto !important;
    padding: 0 16px !important;
    min-width: auto !important;
    font-size: inherit;
    line-height: inherit;
}
.react-tags__search-input input {
    padding: 7px 10px;
    width: 150px !important;
}
/* .react-tags__selected-tag:after {
    content: "\2715";
    color: #aaa;
    margin-left: 8px;
} */
.categoryHeading {
    text-align: left;
    padding-bottom: 24px;
    padding-top: 8px;
}
.react-tags__selected-tag:hover,
.react-tags__selected-tag.active {
    box-shadow: 0.5px 0.5px 6px 1px #86a33c;
    border: 1px solid #86a33c;
}

.react-tags__search {
    display: inline-block;
    padding: 7px 2px;
    margin: 0 0 10px 0;
    max-width: 100%;
}

@media screen and (min-width: 30em) {
    .react-tags__search {
        position: relative;
    }
}

.react-tags__search-input {
    max-width: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    outline: none;
    font-size: inherit;
    line-height: inherit;
}

.react-tags__search-input::-ms-clear {
    display: none;
}

.react-tags__suggestions {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 9999;
}

@media screen and (min-width: 30em) {
    .react-tags__suggestions {
        width: 240px;
    }
}

.react-tags__suggestions ul {
    margin: 4px -1px;
    padding: 0;
    list-style: none;
    background: white;
    border: 1px solid #d1d1d1;
    border-radius: 2px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.react-tags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 6px 8px;
}

.react-tags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
}

.react-tags__suggestions li:hover {
    cursor: pointer;
    background: #eee;
}

.react-tags__suggestions li.is-active {
    background: #b7cfe0;
}

.react-tags__suggestions li.is-disabled {
    opacity: 0.5;
    cursor: auto;
}

.admin .customer-view .nav-tabs .nav-link {
	color: #343a40 !important;
	font-size: 16px;
	padding: 1rem 1.5rem 1rem 1.5rem;
	background-color: #fff;
	cursor: pointer;
	border: none;
}
.admin .admin-menu {
	position: relative;
}
.admin .admin-menu-item {
	padding-left: 0;
	width: 100%;
}
.admin .admin-menu-item.active:before {
	content: '\f053';
	position: absolute;
	top: 5px;
	right: -5px;
	font-family: FontAwesome;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	font-size: 20px;
	color: #c5c5c5;
}
.admin .admin-form-border {
	border: 2px solid #f7f7f7;
	padding: 1rem !important;
	background-color: #f7f7f7;
}
.admin .admin-form-borderless {
	padding: 1rem !important;
	background-color: #f7f7f7;
}
.admin .form-row {
	margin-right: 0;
	margin-left: 0;
}
.admin .configurator-background {
	background-color: #dcdcdc;
	min-height: calc(100vh - 145px);
	margin-top: 0 !important;
}
.admin .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
	color: #495057;
	background-color: #dcdcdc;
	border-radius: 0;
}
.admin .form-control {
	color: #212121;
}
.admin select[disabled] > option {
	color: #212121;
}
.admin .form-control:disabled,
.form-control[readonly] {
	background-color: #e9ecef;
}
.admin .nav-tabs {
	border: none;
}
.admin .form-control:focus {
	box-shadow: none;
}
.admin a.documentation-link {
	color: #86a33c;
	text-decoration: none;
}
.admin a.documentation-link:hover {
	color: #212529;
	text-decoration: none;
}
.admin .loader.documentation-loader {
	border-top-color: rgb(255, 152, 0);
	width: 30px;
	position: relative;
	width: 30px;
	height: 30px;
	border-width: 4px;
	display: inline-block;
	margin-left: 15px;
	vertical-align: middle;
	left: auto;
	top: auto;
}
.admin .filter-separator span {
	background-color: #f7f7f7;
}
.admin .pagination {
	margin-top: 1rem;
}
.admin .react-bs-table-pagination {
	margin-top: 1rem;
	position: relative;
	right: 0;
}
.react-bs-table table td,
.react-bs-table table td button.btn,
.react-bs-table table th {
	font-size: 14px !important;
}
.nav-dropdown {
	display: none;
}
.nav-dropdown .nav-items {
	padding-left: 10px;
	width: 100%;
}
@media (max-width: 1700px) {
	.react-bs-table table td,
	.react-bs-table table td button.btn,
	.react-bs-table table th {
		font-size: 13px !important;
	}
	.react-bs-table .react-bs-container-footer > table > thead > tr > th .filter,
	.react-bs-table .react-bs-container-header > table > thead > tr > th .filter {
		font-size: 13px;
	}
	.admin .customer-view .nav-tabs .nav-link {
		font-size: 14px;
	}
	.admin .navbar-brand,
	.form-control {
		font-size: 14px;
	}
}
@media (max-width: 1400px) {
	.admin .admin-menu-item.active:before {
		top: 3px;
		right: -20px;
	}
}

@media (max-width: 992px) {
	#configurator.admin .configurator-background {
		background: none;
		background-color: transparent;
	}
	#configurator.admin .customer-images.configurator-background,
	.customer-view.configurator-background {
		background-color: #dcdcdc;
	}
	.admin .admin-form-border {
		background-color: #ffffff;
	}
	.admin .calculation .admin-form-border {
		background-color: transparent;
	}
	.admin .admin-menu-item.active:before {
		display: none;
	}
	#configurator a.navbar-brand.active {
		font-weight: 700;
		color: #4e4e4e;
	}
	.react-bs-table-pagination > div > div {
		display: none;
	}
	.admin .pagination {
		margin-top: 1rem;
		float: left;
	}
	.nav-dropdown {
		width: 100%;
		display: inline;
	}
	.nav-form {
		display: none;
	}
}
.admin label.form-check-label {
	display: inline-block;
}
.admin input[type='checkbox'] {
	cursor: pointer;
}
.admin .react-bs-table textarea {
	background-color: transparent !important;
	border-color: transparent !important;
	font-size: 13px !important;
	padding-top: 0 !important;
}
.admin td.no-elipsis {
	overflow: visible;
	white-space: normal;
	text-overflow: unset;
}
.admin .inquiry .table-hover tbody tr:hover {
	background-color: rgb(218, 218, 218);
}
.table-summary {
	margin: 5px 0 !important;
	text-align: right;
}
.tax-dropdown {
	float: right;
}

.print-dropdown {
	display: inline-block;
	padding: 5px;
}
.configuration-price {
	text-transform: uppercase;
	font-weight: 700;
}
.react-date-picker__wrapper {
	border: none !important;
}

.login {
    text-align: left;
    padding: 1em;
    margin: 1em;
    border: 2px solid #d3d3d3;
    border-radius: 0.5em;
    vertical-align: middle;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
}

.form {
    padding: 1em;
}

label {
    display: flex;
    font-weight: 600;
}

button {
    justify-content: flex-end;
}

.login-title {
    font-size: 1.5em;
}

.login-intro {
    font-size: large;
}
.login .was-validated .form-control:valid,
.login .form-control.is-valid {
    background-image: none;
}
@-webkit-keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
@keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

.reset-password {
    text-align: left;
    padding: 1em;
    margin: 1em;
    border: 2px solid #d3d3d3;
    border-radius: .5em;
    vertical-align: middle;
    margin-left: auto;
    margin-right: auto;
    width: 600px;
    margin-top: 50px;
}

.form {
    padding: 1em;
}

label {
    display: flex;
    font-weight: 600;
}

button {
    justify-content: flex-end;
}

.reset-password-title {
    font-size: 1.5em;
}

.reset-password-intro {
    font-size: large;
}
.reset-password .was-validated .form-control:valid, .reset-password .form-control.is-valid {
    background-image: none;
}
@-webkit-keyframes App-logo-spin {
    from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
@keyframes App-logo-spin {
    from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.Register {
    text-align: left;
    padding: 1em;
    margin: 1em;
    border: 2px solid #d3d3d3;
    border-radius: 0.5em;
    vertical-align: middle;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
}
.toggle-password {
    float: right;
    margin-right: 16px;
    margin-top: -24px;
    position: relative;
    z-index: 2;
}
input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
    display: none;
}
.form {
    padding: 1em;
}

label {
    display: flex;
    font-weight: 600;
}

button {
    justify-content: flex-end;
}

.Register-title {
    font-size: 1.5em;
}

.Register-intro {
    font-size: large;
}

@-webkit-keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

.public .configurator-background {
	background-color: #dcdcdc;
	min-height: calc(100vh - 85px);
	margin-top: 0 !important;
}

@media (max-width: 992px) {
	#configurator.public .configurator-background {
		background: none;
		background-color: transparent;
	}
	#configurator.public .customer-images.configurator-background{
		background-color: #dcdcdc;
	}
}
