.reset-password {
    text-align: left;
    padding: 1em;
    margin: 1em;
    border: 2px solid #d3d3d3;
    border-radius: .5em;
    vertical-align: middle;
    margin-left: auto;
    margin-right: auto;
    width: 600px;
    margin-top: 50px;
}

.form {
    padding: 1em;
}

label {
    display: flex;
    font-weight: 600;
}

button {
    justify-content: flex-end;
}

.reset-password-title {
    font-size: 1.5em;
}

.reset-password-intro {
    font-size: large;
}
.reset-password .was-validated .form-control:valid, .reset-password .form-control.is-valid {
    background-image: none;
}
@keyframes App-logo-spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}
