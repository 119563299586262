.react-tags {
    position: relative;
    text-align: left;
    font-size: 1em;
    line-height: 1.2;
    cursor: text;
    margin-left: -2px;
    float: left;
}

.react-tags.is-focused {
    border-color: #b1b1b1;
}

.react-tags__selected {
    display: inline;
}

.react-tags__selected-tag {
    display: inline-block;
    box-sizing: border-box;
    margin: 0 6px 6px 0;
    border: 1px solid #212529;
    border-radius: 0;
    background: #fff;
    width: auto !important;
    padding: 0 16px !important;
    min-width: auto !important;
    font-size: inherit;
    line-height: inherit;
}
.react-tags__search-input input {
    padding: 7px 10px;
    width: 150px !important;
}
/* .react-tags__selected-tag:after {
    content: "\2715";
    color: #aaa;
    margin-left: 8px;
} */
.categoryHeading {
    text-align: left;
    padding-bottom: 24px;
    padding-top: 8px;
}
.react-tags__selected-tag:hover,
.react-tags__selected-tag.active {
    box-shadow: 0.5px 0.5px 6px 1px #86a33c;
    border: 1px solid #86a33c;
}

.react-tags__search {
    display: inline-block;
    padding: 7px 2px;
    margin: 0 0 10px 0;
    max-width: 100%;
}

@media screen and (min-width: 30em) {
    .react-tags__search {
        position: relative;
    }
}

.react-tags__search-input {
    max-width: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    outline: none;
    font-size: inherit;
    line-height: inherit;
}

.react-tags__search-input::-ms-clear {
    display: none;
}

.react-tags__suggestions {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 9999;
}

@media screen and (min-width: 30em) {
    .react-tags__suggestions {
        width: 240px;
    }
}

.react-tags__suggestions ul {
    margin: 4px -1px;
    padding: 0;
    list-style: none;
    background: white;
    border: 1px solid #d1d1d1;
    border-radius: 2px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.react-tags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 6px 8px;
}

.react-tags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
}

.react-tags__suggestions li:hover {
    cursor: pointer;
    background: #eee;
}

.react-tags__suggestions li.is-active {
    background: #b7cfe0;
}

.react-tags__suggestions li.is-disabled {
    opacity: 0.5;
    cursor: auto;
}
