#configurator .sideBarMenuContainer {
	position: fixed;
	height: 100%;
}

#configurator .userMenu {
	width: 100%;
	text-align: center;
	margin-right: 0px;
	margin-left: 0px;
	margin-top: 0px;
}

#configurator nav.sidebar :global(.navbar-brand) :global(.glyphicon) {
	margin-right: 0px;
}

/*Remove rounded coners*/
#configurator nav.sidebar :global(navbar) {
	border-radius: 0px;
}

#configurator nav.sidebar {
	-webkit-transition: margin 200ms ease-out;
	-moz-transition: margin 200ms ease-out;
	-o-transition: margin 200ms ease-out;
	transition: margin 200ms ease-out;
}

/* .....NavBar: Icon only with coloring/layout.....*/

/*small/medium side display*/
@media (min-width: 768px) {
	/*Center Brand*/
	#configurator nav.sidebar :global(navbar).sidebar > .container :global(.navbar-brand),
	:global(.navbar) > :global(.container-fluid) :global(.navbar-brand) {
		margin-left: 0px;
	}
	/*Center Brand*/
	#configurator nav.sidebar :global(.navbar-brand),
	nav.sidebar :global(.navbar-header) {
		text-align: center;
		width: 100%;
		margin-left: 0px;
	}

	/*Center Icons*/
	#configurator nav.sidebar a {
		padding-right: 13px;
	}

	/*adds border top to first nav box */
	#configurator nav.sidebar :global(.navbar-nav) > li:first-child {
		border-top: 1px #e5e5e5 solid;
	}

	/*adds border to bottom nav boxes*/
	#configurator nav.sidebar :global(.navbar-nav) > li {
		border-bottom: 1px #e5e5e5 solid;
	}

	/* Colors/style dropdown box*/
	#configurator nav.sidebar :global(.navbar-nav) :global(.open) :global(.dropdown-menu) {
		position: static;
		float: none;
		width: auto;
		margin-top: 0;
		background-color: transparent;
		border: 0;
		-webkit-box-shadow: none;
		box-shadow: none;
	}

	/*allows nav box to use 100% width*/
	#configurator nav.sidebar :global(.navbar-collapse),
	nav.sidebar :global(.container-fluid) {
		padding: 0 0px 0 0px;
	}

	/*colors dropdown box text */
	#configurator :global(.navbar-inverse) :global(.navbar-nav) :global(.open) :global(.dropdown-menu) > li > a {
		color: #777;
	}

	/*gives sidebar width/height*/
	#configurator nav.sidebar {
		width: 200px;
		height: 100%;
		margin-left: -160px;
		float: left;
		z-index: 8000;
		margin-bottom: 0px;
	}

	/*give sidebar 100% width;*/
	#configurator nav.sidebar li {
		width: 100%;
	}

	/* Move nav to full on mouse over*/
	#configurator nav.sidebar:hover {
		margin-left: 0px;
	}
	/*for hiden things when navbar hidden*/
	#configurator :global(.forAnimate) {
		opacity: 0;
	}
}

/* .....NavBar: Fully showing nav bar..... */
@media (min-width: 1330px) {
	/*Show all nav*/
	#configurator nav.sidebar {
		margin-left: 0px;
		float: left;
	}
	/*Show hidden items on nav*/
	#configurator nav.sidebar :global(.forAnimate) {
		opacity: 1;
	}
}

#configurator nav.sidebar :global(.navbar-nav) :global(.open) :global(.dropdown-menu) > li > a:hover,
nav.sidebar :global(.navbar-nav) :global(.open) :global(.dropdown-menu) > li > a:focus {
	color: #ccc;
	background-color: transparent;
}

#configurator nav:hover :global(.forAnimate) {
	opacity: 1;
}

#configurator section {
	padding-left: 15px;
}
#configurator .navbar-wrapper {
	width: 100%;
	border-top: 1px solid #cacaca;
}
#configurator.admin .navbar-wrapper:first-child {
	border-top: none;
}
#configurator .admin-menu.navbar-wrapper {
	border-top: none;
}
#configurator .navbar-toggler-icon,
.inquiry .navbar-toggler-icon {
	background-image: url('../images/menu-expand.png');
}
#configurator .navbar-toggler-icon,
.inquiry .navbar-toggler-icon {
	display: inline-block;
	width: 0.5em;
	height: 0.5em;
	vertical-align: baseline;
	content: '';
	background-repeat: no-repeat;
	background-position: center center;
	background-size: auto;
}
#configurator .navbar-toggler {
	padding-left: 0;
	padding-right: 0;
}
#configurator legend {
	font-size: 14px;
	font-weight: 700;
}
/* #configurator .navbar {
    border-top: 1px solid #6F6F6F;
    border-bottom: 1px solid #6F6F6F;
} */
#configurator .door-item {
	padding: 1rem 2rem 1rem 2rem;
	cursor: pointer;
}

@media (min-width: 1000px) {
	#configurator .door-item {
		padding: 1rem;
	}
	#configurator .doors-wrapper {
		padding: 0 1rem;
	}
}
@media (min-width: 1500px) {
	#configurator .door-item {
		padding: 1.5rem 2rem 0.5rem 2rem;
	}
}
@media (min-width: 1800px) {
	#configurator .door-item {
		padding: 1.5rem 2.5rem 0.5rem 2.5rem;
	}
}
#configurator .door-item {
	-webkit-transition: 0.3s ease-in-out;
	transition: 0.3s ease-in-out;
	cursor: pointer;
	text-align: center;
}
#configurator .door-item:hover {
	-webkit-transform: scale(1.07);
	transform: scale(1.07);
	-webkit-transition: 0.3s ease-in-out;
	transition: 0.3s ease-in-out;
}
#configurator .door-item div {
	text-align: center;
	text-overflow: ellipsis;
	overflow: hidden;
}
#configurator .door-item > img {
	vertical-align: middle;
	max-width: 100%;
}
#configurator img.selected-item {
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	position: absolute;
	z-index: 1;
	width: 56px;
}
#configurator.step2 .configurator-background,
#configurator.step3 .configurator-background {
	min-height: calc(100vh);
}
@media (max-width: 1400px) {
	#configurator img.selected-item {
		width: 42px;
	}
}
@media (max-width: 1400px) {
	#configurator input[type='search'] {
		font-size: 14px;
	}
}
@media (max-width: 992px) {
	.search-icon {
		display: none;
	}
}
@media (min-width: 768px) {
	#configurator .configurator-background {
		background-color: #dcdcdc;
	}
	.configurator-buttons .button.next-page {
		width: 27%;
		margin-right: 3%;
		margin-bottom: 0px;
	}
}

#configurator input[type='search'] {
	background-color: #dcdcdc;
	border: none;
	height: 45px;
	border-radius: 0;
	/* -webkit-box-shadow: none;
    box-shadow: none; */
}

.react-bs-table table {
	font-size: 14px;
}

/* #configurator textarea.form-control {
    background-color: #eae9e9;
    border-color: #dcdcdc;
    color:#212121;
    border-radius: 0;
    -webkit-box-shadow: none;
        box-shadow: none;
} */

#configurator input.form-control::placeholder,
#configurator textarea.form-control::placeholder {
	color: inherit;
	opacity: 1;
}
#configurator input.form-control::placeholder,
#configurator textarea.form-control::placeholder {
	color: inherit;
}
#configurator input.form-control::placeholder,
#configurator textarea.form-control::placeholder {
	color: inherit;
}

#configurator .parameter-image {
	display: block;
	margin-left: 50%;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	padding-bottom: 3rem;
	/* height: 80vh;  */
	max-width: 100%;
	padding-top: 1rem;
}
#configurator .configurator-button {
	display: inline-block;
	padding: 5px 10px;
}
.search-icon {
	position: absolute;
	margin-left: -75px;
	margin-top: 2px;
	padding: 10px;
	cursor: pointer;
	opacity: 0.6;
}
.clear-icon {
	position: absolute;
	margin-left: -56px;
	margin-top: 14px;
	/* padding: 10px; */
	cursor: pointer;
	width: 16px;
}
.search-input::-webkit-search-cancel-button {
	-webkit-appearance: none;
}
/*filters*/
.filter-reset {
	text-align: right;
	cursor: pointer;
	margin-top: 23px;
}
.configuration-reset {
	text-align: right;
	cursor: pointer;
	float: right;
	padding-top: 7px;
	margin-right: -30px;
}
.filters-navbar {
	padding-right: 0;
}
/* .filters-navbar.filters-navbar .navbar-brand {margin-bottom:0.5rem; margin-top:1rem} */
.filters-navbar .form-check {
	margin-bottom: 5px;
}
.filter-image {
	display: inline-block;
	vertical-align: middle;
	top: 13px;
	right: 15px;
	position: absolute;
}
.disable-edit .filter-image {
	display: none;
}
.filter-image-s {
	right: 15px;
	top: -21px;
	position: absolute;
	display: inline-block;
	cursor: pointer;
}
.filter-separator {
	width: 100%;
	height: 12px;
	border-bottom: 1px solid #cacaca;
	text-align: center;
}
.filter-separator span {
	padding-right: 1rem;
	background-color: #f5f5f5;
	font-size: 14px;
	float: left;
}

.configuration-filter button {
	width: 100%;
	padding-right: 40px;
	min-height: 33px;
	min-width: 140px;
	position: relative;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.configurator-filters.navbar {
	padding: 0 0 1rem 0;
}
.btn-group > .btn-configurator.btn:focus,
.btn-group > .btn-configurator.btn:active {
	z-index: 1;
	background: #dcdcdc;
}

.filters-navbar.filters-navbar .navbar-brand {
	width: calc(100% - 28px);
	cursor: pointer;
}
.step1 .filters-navbar.filters-navbar .navbar-brand {
	width: calc(100% - 10px);
	cursor: pointer;
}
.toggler-step3 {
	width: 113%;
}
.overview {
	width: 100%;
	font-size: 14px;
	overflow-x: hidden;
}
#configurator .overview a.navbar-brand {
	font-weight: 500;
	font-size: 14px;
}
.overview thead tr {
	background: rgb(253, 253, 253);
}

.overview th {
	background-color: #f5f5f5;
	font-weight: 500;
}
.overview thead:first-child th {
	padding-top: 1rem;
}
.overview th.th-right {
	background-color: #f5f5f5;
	padding: 1rem;
}
.admin .overview thead:first-child th {
	padding: 2rem 2rem 1rem 2rem;
}
.admin .overview th.th-right {
	background-color: #f5f5f5;
	padding: 2rem 2rem 1rem 2rem;
}
.overview th.th-left {
	padding: 1rem;
}
.admin .overview th.th-left {
	padding: 2rem 2rem 1rem 2rem;
}
.overview td {
	padding: 0.5rem;
}

.overview .spacer {
	height: 0.5rem;
	background: rgb(253, 253, 253);
	border: none;
	padding: 0;
}

.overview .money-col {
	float: right;
}

.overview hr {
	padding: 0;
	margin: 8px 0;
}

.inquiry {
	margin: auto;
}

.inquiry .head-column {
	width: 100%;
}
.inquiry .toggler-title {
	font-size: 15px;
	font-weight: 500;
}
.inquiry .half-column {
	width: 100%;
	padding: 10px 20px;
	margin-bottom: -20px;
}

.inquiry .full-column {
	width: 100%;
	padding: 30px 20px 0 20px;
}

.inquiry .foot-column {
	width: 100%;
	padding: 20px 20px 0 60px;
}

.inquiry input {
	margin-bottom: 10px;
}

.inquiry input[type='search'] {
	background-color: #dcdcdc;
}

.inquiry .checkbox {
	padding: 0 20px 0 20px;
}

input[type='checkbox'] {
	transform: scale(1.3);
	border: none;
}
.inquiry input[type='checkbox'] {
	transform: scale(1.7);
	border: none;
	margin-left: -14px;
}

.contact input[type='checkbox'] {
	transform: scale(1.7);
	border: none;
	margin-left: 5px;
}

.contact .checkbox {
	padding: 0 20px 0 30px;
	margin-top: 5px;
}

.Register .checkbox {
	padding: 0 20px 0 20px;
}

.Register input[type='checkbox'] {
	margin-left: 0px;
}

.configurator-filters input[type='checkbox'] {
	transform: none;
	border: none;
}
.configurator-filters .navbar-toggler-icon {
	margin-top: -5px;
}
.configurator-buttons {
	width: 100%;
	margin-top: 20px;
	background-color: #f7f7f7;
	position: sticky;
	bottom: -1px;
	left: 0;
	z-index: 1;
}
.configurator-buttons .button {
	height: 50px;
}
.configurator-buttons .button.send-inquiry {
	width: 100%;
}
.inquiry hr {
	background-color: darkgray;
}

.inquiry .foot-column .bolder {
	font-weight: 500 !important;
	margin-right: 20px;
}

.inquiry .consensus-button {
	float: right;
	margin-left: 10px;
	border: none !important;
}

.inquiry h6 {
	position: relative;
	overflow: hidden;
	text-align: left;
	font-weight: normal;
}

.inquiry h6:after {
	display: inline-block;
	content: '';
	position: absolute;
	width: 100%;
	top: 50%;
	margin-top: -2px;
	margin-left: 10px;
	background-color: darkgray;
	height: 1px;
}
.inquiry #newproject-checkbox {
	margin-left: 5px;
}
.copy-popup input[type='checkbox'] {
	margin-left: 3px;
}
.copy-popup .checkbox {
	padding: 0 20px 0 25px;
}
.inquiry-popup {
	max-width: 50vw;
}
@media (max-width: 700px) {
	.inquiry-popup {
		max-width: 100vw;
	}
}
.step-of {
	line-height: 30px;
	padding-left: 1rem;
}
.step-of label {
	display: block;
}
button.dropdown-toggle.btn.btn-categories {
	text-transform: uppercase;
	font-size: 1.1rem;
	padding: 1rem 0;
}
.dropdown-categories .dropdown-menu {
	padding: 0;
}
.dropdown-categories .dropdown-item {
	text-transform: uppercase;
	font-size: 1.1rem;
	padding: 0.5rem 1.5rem;
}
.dropdown-item:hover,
.dropdown-item:focus {
	color: #16181b;
	text-decoration: none;
	background-color: #dcdcdc !important;
}

.category-row {
	border-bottom: 1px solid gray;
}
.type-IntegerT .dropdown-menu.show {
	width: 100%;
	border: 0;
	padding: 5px;
}
.slider {
	width: 100%;
	margin-top: -36px;
	text-align: center;
}
.slider-spacer {
	margin-top: 16px;
}
.slider input {
	height: 23px;
	color: #212121;
	font-size: 14px;
	font-weight: 500;
	text-align: center;
	outline-color: #86a33c;
	max-width: 120px;
}
.slider-spacer-bellow {
	height: 56px;
	background-color: #f7f7f7;
}
.configuration-filter.type-1 .dropdown-menu {
	width: 100%;
	border: 0;
	height: 50px;
}
.customer-view .customer-table {
	margin-bottom: 20px;
}
.customer-view .table-hover tbody tr:hover,
.inquiry .table-hover tbody tr:hover {
	background-color: rgb(218, 218, 218);
}
.customer-view .customer-table th input {
	font-size: 14px;
	height: 100% !important;
	margin-top: 8px;
}
.customer-view .table thead tr th,
.table tbody tr td {
	border: none;
}
.loading-image {
	position: relative;
	height: 90vh;
	width: 100%;
}
.loading-image.part {
	height: 80vh;
}
.loading-image img {
	width: 150px;
	position: absolute;
	left: calc(50% - 75px);
	top: calc(50% - 130px);
	opacity: 0.28;
}
#configurator a.navbar-brand {
	color: #212121;
}
/*admin part*/
.admin-configurator-buttons {
	position: absolute;
	z-index: 9999;
	top: 21px;
}
.react-bs-table-sizePerPage-dropdown {
	display: none;
}
.react-bs-table-pagination {
	margin-top: -6px;
	position: absolute;
	right: 1rem;
}
.page-item.active .page-link {
	z-index: 1;
	color: #fff;
	background-color: #b7b7b7;
	border-color: #b7b7b7;
}

.cart-container {
	padding: 1rem !important;
}

#configurator .fa-expand {
	padding-top: 10px;
	margin-right: -15px;
	font-size: 20px;
}
#configurator.step1 .loader {
	left: 1rem;
	top: 2rem;
	border: 16px solid #fff;
}
@media print {
	#configurator .parameter-image {
		height: 50vh;
	}
	.AppFooter {
		display: none;
	}
}
.special-bold {
	font-weight: 500 !important;
}
@media (max-width: 767px) {
	.configurator-filters.navbar {
		padding: 0 !important;
	}
	.configurator-buttons {
		position: fixed;
		padding-bottom: 40px;

		.next-page,
		.tree {
			width: 40% !important;
		}
	}
	#configurator.step2 .configurator-background,
	#configurator.step3 .configurator-background {
		min-height: calc(100vh + 120px);
	}
}

.treemodal {
	max-width: 100%;
}

.parameter-image-loading {
	position: absolute;
	height: 100%;
	width: 100%;
	opacity: 0.7;
	background-color: #dcdcdc;
	z-index: 2000;
}
