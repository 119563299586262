.public .configurator-background {
	background-color: #dcdcdc;
	min-height: calc(100vh - 85px);
	margin-top: 0 !important;
}

@media (max-width: 992px) {
	#configurator.public .configurator-background {
		background: none;
		background-color: transparent;
	}
	#configurator.public .customer-images.configurator-background{
		background-color: #dcdcdc;
	}
}