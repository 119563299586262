header nav {
    background-color: #86a33c;
    border: 0;
}

header a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
    color: #fff;
    font-weight: 100;
    font-size: 22px;
    vertical-align: middle;
    margin-right: 0;
    padding: 0;
    display: flex;
    align-items: baseline;
    max-height: 40px;
}

header {
    font-size: 14px;
}

@media (min-width: 768px) {
    header {
        font-size: 16px;
    }
}

header .box-shadow {
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
}

header .dropdown {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}

header .fa {
    padding-right: 2px;
}

header .logo {
    max-width: 120px;
    max-height: 40px;
    vertical-align: middle;
}

header .logotip {
    max-width: 100px;
    max-height: 40px;
    right: 0;  
    margin: auto;
}
header .logo-frame {
    height: 40px;
    max-width: 100px;
    text-align: right;
    vertical-align: middle;
    display: table-cell;
}
.outer-wrapper{
        margin-left: auto;
        display: inline-block; 
        margin-top: 3px;
    }

.logo-title {
    margin-left: 5px;
}

@media (max-width: 575px) {
    header .logo {
        margin-left: 10px;
        max-width: 120px;
        max-height: 32px;
    }
    .navbar-nav button.bordered.dropdown-toggle.btn.btn-secondary {
        padding: 0px;
        border: 0;
        margin: 2px 0;
    }

    .header-menu .navbar-nav{
        justify-content: flex-end;
    }
    header a.navbar-brand {
        font-size: 18px;
    }
    .outer-wrapper{
        display: none;
    }
}
header .header-menu button span {
    padding-right: 15px;
    padding-left: 5px;
    vertical-align: middle;
}
@media (max-width: 575px) {
    header .header-menu .dropdown-toggle::after {
        display: none;
    }
}
header .header-menu .dropdown-toggle::after {
    vertical-align: middle;
}
.header-menu .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
}

.navbar-toggler-icon {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAHCAYAAAA8sqwkAAAAiklEQ…kJPOZV42SDE3ycxYsjY/In2DRsgb2dkp5rYqOU8qq1zsDuA06eMUefCnqYAAAAAElFTkSuQmCC);
}
@media (max-width: 1100px) {
    .logo-title {
        display: none;
    }
}

.react-numeric-input > input {
    width: 70px;
}

header .navbar-nav .dropdown-menu {
    margin-top: 6px;
}
