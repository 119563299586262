@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap');
html {
	/* overflow-y: scroll; */
	font-size: 14px;
}
body {
	font-family: 'Roboto', sans-serif;
	color: #212121;
	background-color: #f7f7f7;
	padding: 0 !important;
}
.dropdown-selector {
	font-size: inherit !important;
}
.text-light {
	color: #fff !important;
}

a.text-light:hover,
a.text-light:focus {
	color: #cbd3da !important;
}

.text-dark {
	color: #343a40 !important;
}

a.text-dark:not(.nav-link):hover,
a.text-dark:not(.nav-link):focus {
	color: #777 !important;
}

.dropdown-item.active,
.dropdown-item:active {
	color: inherit;
	background-color: #dcdcdc;
}
.no-padding {
	padding-right: 0 !important;
	padding-left: 0 !important;
}
.no-margin {
	margin-right: 0 !important;
	margin-left: 0 !important;
}
.inline-image {
	display: inline-block;
	vertical-align: middle;
}

.text-small {
	font-size: 14px;
}
.text-smaller {
	font-size: 12px;
}
input[type='radio'],
.form-check-label {
	cursor: pointer;
}
/* input[type=text].form-control:focus, input[type=search].form-control:focus {
    border-color: #86A33C;
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.2);
} */

.navbar-brand {
	font-size: 1rem;
	letter-spacing: normal;
}

label {
	font-weight: normal !important;
}
label.smaller {
	margin-bottom: 0;
	font-size: 14px;
}
input.smaller,
textarea.smaller {
	font-size: 14px;
}
.switch-wrapper {
	position: relative;
}

.switch-wrapper > div {
	position: absolute;
	width: 100%;
}
/* .container {
    max-width:100% !important;
    padding-left:2rem;
    padding-right:2rem;

} */
.navbar-toggler {
	border: 0;
}
header .navbar {
	padding: 0.5rem 0;
	box-shadow: 0 0.6666666667em 3.6666666667em 0 rgba(0, 0, 0, 0.1);
	z-index: 1;
}
header .nav-link {
	padding: 0.5rem 0;
}
.disable-cursor {
	cursor: default !important;
}
a,
.link-button {
	color: #345ec1;
}
a.green {
	color: #86a33c;
}
a:hover {
	color: #212121;
}
/* .form-control:focus {
    border-color: #86A33C;
    box-shadow: 0 0 0 0.1rem #86A33C;
} */
.form-control:focus {
	border-color: #495057;
	box-shadow: none !important;
}
.react-bootstrap-table-page-btns-ul {
	margin-top: -10px !important;
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected {
	background-color: #86a33c !important;
}
#loading {
	display: none;
}
.modal-open {
	overflow: visible;
}
.was-validated .form-control:valid,
.form-control.is-valid {
	border-color: #86a33c !important;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23FFFFFF' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
}
div.show-valid {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23FFFFFF' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
	width: 18px;
	height: 36px;
	display: inline-block;
	background-repeat: no-repeat;
	background-position: center;
	vertical-align: middle;
	margin-left: 7px;
}
/* .form-control.is-valid:focus {
    border: 0;
    box-shadow: 0 0 0 1px #495057;
} */
/* .was-validated .form-control:valid, .form-control.is-valid {
    border-color:transparent;
} */
.page-link {
	position: relative;
	display: block;
	padding: 0.5rem 0.75rem;
	margin-left: -1px;
	line-height: 1.25;
	color: #86a33c;
	background-color: #fff;
	border: 1px solid #dee2e6;
	font-size: 14px !important;
}
.table-striped tbody tr:nth-of-type(odd) {
	background-color: rgba(242, 245, 245, 0.8);
}
.table-striped tbody tr:nth-of-type(even) {
	background-color: #fff;
}
.react-bs-table-bordered {
	border-radius: 0 !important;
	border: 1px solid #cecece !important;
}
.react-bs-table table td {
	outline: none !important;
}
.categories-loader {
	position: fixed;
	left: 0;
	/* right: 0; */
	top: 0;
	/* bottom: 0; */
	width: 100%;
	/* margin: 0 auto; */
	height: 100%;
	background: rgba(0, 0, 0, 0.2);
	/* background-color: white; */
}
.loader {
	border: 16px solid #f3f3f3; /* Light grey */
	border-top: 16px solid #3498db; /* Blue */
	border-radius: 50%;
	width: 120px;
	height: 120px;
	animation: spin 2s linear infinite;
	position: absolute;
	left: calc(50% - 60px);
	top: 27vh;
}
.loader.part-loader {
	top: calc(38vh - 60px);
}
@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
.loader.fixed {
	position: relative;
}
.loader.top {
	top: 0;
}
.categories-loader .loader {
	top: 40%;
}
.login-loader.loader {
	top: 25vh;
}
@media (min-width: 768px) {
	.categories-loader {
		display: none;
	}
}
:focus {
	outline: none;
}
@media (max-width: 767px) {
	body,
	.dropdown-menu {
		font-size: 14px;
	}
	header .dropdown {
		padding-right: 5px;
		padding-left: 5px;
	}
}
.form-control.is-valid,
.was-validated .form-control:valid,
.form-control.is-invalid,
.was-validated .form-control:invalid {
	background-position-x: calc(100% - 10px);
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	transition: background-color 5000s ease-in-out 0s;
}
.table {
	color: #4e4e4e;
}
.no-pointer-events {
	pointer-events: none;
}
body,
.dropdown-menu,
.btn,
.form-control,
.dropdown-selector {
	font-size: 14px !important;
}
.navbar-brand {
	font-size: 14px;
}
.close span {
	font-size: 18px;
}
.cursor-pointer {
	cursor: pointer;
}
a.cookie-info {
	color: #f7f7f7;
	text-decoration: underline;
}
.cookie-info:hover {
	color: #dcdcdc;
	text-decoration: underline;
}
.react-numeric-input b {
	width: 2.3ex !important;
}

.required:after {
	content: ' *';
	color: red;
}

.AppFooter {
	position: fixed;
	right: 0;
	z-index: 5;
	bottom: 0;
	background-color: rgba(255, 255, 255, 0.7);
	padding: 1px 10px;
	border-top-left-radius: 4px;
}

.AppFooterCopyright,
.AppFooterGdpr,
.AppFooterTerms,
.AppFooterVersion {
	float: right;
	font-size: 0.7em;
	font-weight: 400;
	color: #000;
	padding-right: 20px;
	text-shadow: 1px 1px 1px rgba(162, 162, 162, 0.8);
}

.AppFooterLink {
	color: #000;
}
